const zh = {
  Language: "中文",
  headertag: [
    {
      index: 0,
      active: false,
      router: "/home",
      Width: "36px",
      margin: "0 0 0 0",
      routerName: "首页",
    },
    {
      index: 1,
      active: false,
      router: "/speaking",
      Width: "72px",
      margin: "0 0 0 57px",
      routerName: "口语题库",
    },
    {
      index: 2,
      active: false,
      router: "/writing",
      Width: "72px",
      margin: "0 0 0 64px",
      routerName: "写作题库",
    },
    {
      index: 3,
      active: false,
      router: "/listening",
      Width: "86px",
      margin: "0 0 0 50px",
      routerName: "听力题库",
    },
    {
      index: 4,
      active: false,
      router: "/reading",
      Width: "86px",
      margin: "0 0 0 50px",
      routerName: "阅读题库",
    },
    {
      index: 5,
      active: false,
      router: "/AllMode2",
      Width: "86px",
      margin: "0 0 0 50px",
      routerName: "套题模考",
    },
    {
      index: 5,
      active: false,
      router: "/company-intro",
      Width: "86px",
      margin: "0 0 0 50px",
      routerName: "企业服务",
    },
  ],
  Speaking: {
    Speakingbefore: {
      roterHome: "首页",
      roterNmae: "口语题库",
      plstxt: "请输入",
      cardtxt: {
        gotest: "去练习",
        showMore: "查看更多",
        up: "收起",
        flname: "分类",
        flList: [{ name: "全部" }, { name: "环境类" }, { name: "学习类" }],
        xxname: "进度",
        xxList: [{ name: "全部" }, { name: "未学习" }, { name: "已学习" }],
        txname: "题型",
        txList: [{ name: "Part1" }, { name: "Part2" }, { name: "Part3" }],
        scname: "收藏",
        scList: [{ name: "全部" }, { name: "未收藏" }, { name: "已收藏" }],
        ndname: "难度",
        ndList: [
          { name: "全部" },
          { name: "简单" },
          { name: "中等" },
          { name: "困难" },
        ],
        pgname: "批改",
        pgList: [{ name: "全部" }, { name: "是" }, { name: "否" }],
        xjname: "更新",
        xjList: [{ name: "保留题" }, { name: "更新题" }],
      },
    },
  },
};
export default zh;
